/* eslint-disable camelcase */
import React from 'react';
import { graphql } from 'gatsby';
import 'moment/locale/nl-be';
import moment from 'moment';
import GameReviewCard from '../components/GameReviewCard';
import slugify from '../utils/slugify';

import Layout from '../layout/defaultLayout';

import '../stylesheets/posts.sass';
import Container from '../components/layout/Container';

const wedstrijdverslagen = ({ data }) => {
  moment.locale('nl-be');
  return (
    <Layout>
      <div id="posts">
        <Container>
          {data.allContentfulWedstrijdverslag.edges.map(edge => {
            const {
              contentful_id,
              titel,
              hoofdafbeelding,
              createdAt,
              childContentfulWedstrijdverslagVerslagTextNode,
              // auteur,
              // ploeg,
            } = edge.node;
            return (
              <GameReviewCard
                key={contentful_id}
                url={`/${slugify(titel)}`}
                image={hoofdafbeelding}
                title={titel}
                excerpt={childContentfulWedstrijdverslagVerslagTextNode.childMarkdownRemark.excerpt}
                date={createdAt}
              />
            );
          })}
        </Container>
      </div>
    </Layout>
  );
};

export default wedstrijdverslagen;

export const Postquery = graphql`
  query {
    allContentfulWedstrijdverslag {
      edges {
        node {
          contentful_id
          titel
          auteur
          ploeg {
            naam
            guid
          }
          hoofdafbeelding {
            fluid(maxWidth: 400) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          childContentfulWedstrijdverslagVerslagTextNode {
            childMarkdownRemark {
              html
              excerpt
            }
          }
          createdAt
        }
      }
    }
  }
`;
